import React ,{useRef}from 'react';
import './invoice.scss'
import IrtizaDynamicInputList from '../dynamic-input/dynamic-input-list';
import moment from 'moment'
import SecurityService from '../security/security.service';
import InvoiceService from './invoice.service';
import reportsService from '../reports/reports.service';
import InvoiceAdd from './invoice-add';
import AuditTable from '../audit-table/audit-table'
import invoiceService from './invoice.service';
import DialogInput from '../dynamic-input/popup-dialog-input';




const InvoiceInput = (props) => {
  console.log(props)
  let inputType = props.location.pathname.split('/')[2]
      console.log(inputType)
  
    let columns = inputType == 'Sale' || SecurityService.getLoggedInUserId() == '0' ? [

      // { field: "InvoiceDetailID", headerText: 'ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
      { field: "MainInvoiceID", headerText: 'Main Invoice ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
      { field: "InvoiceID", headerText: 'Invoice ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
      { field: "InvoiceDate", headerText: 'Invoice Date', width: '130px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "InvoiceNo", headerText: 'Invoice No', width: '160px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "AccountTitle", headerText: 'Account Title', width: '150px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "AccountTitleBill", headerText: 'Account Title Bill', width: '150px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "CurrencyName", headerText: 'Currency', width: '135px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "ShopName", headerText: 'Shop Name', width: '140px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "AdjustmentAmountFC", headerText: 'Adjustment Amount', width: '140px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n2',visible:SecurityService.getLoggedInUserId() == '0' && inputType == 'Purchase'}] },
      { field: "FreightAmountFC", headerText: 'Freight Amount', width: '140px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n2',visible:SecurityService.getLoggedInUserId() == '0' && inputType == 'Purchase'}] },

      { field: "Remarks", headerText: 'Remarks', width: '200px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      // { field: "BrandName", headerText: 'Brand Name', width: '130px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      // { field: "ProductName", headerText: 'Product Name', width: '140px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "TotalInvoiceQuantity", headerText: 'Total Invoice Quantity', width: '130px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0'}] },
      // { field: "InvoiceRate", headerText: 'Invoice Rate', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n2'}] },
      { field: "TotalInvoiceCost", headerText: 'Total Invoice Cost', width: '130px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n2'}] },
      // { field: "UnitOfMeasurement", headerText: 'Unit of Measurement', width: '160px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      // { field: "WeightPerBale", headerText: 'Weight Per Bale', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0'}] },
      // { field: "TotalInvoiceWeight", headerText: 'Total Invoice Weight', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0'}] },
      ]: 
      // MainInvoiceID ,InvoiceID ,InvoiceDate ,InvoiceNo ,AccountTitle ,AccountTitleBill ,CurrencyName	,ShopName ,Remarks	,BrandName ,ProductName ,InvoiceQuantity ,UnitOfMeasurement	,InvoiceDetailID	DetailKey
      [
        // { field: "InvoiceDetailID", headerText: 'ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
        { field: "MainInvoiceID", headerText: 'Main Invoice ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
        { field: "InvoiceID", headerText: 'Invoice ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
        { field: "InvoiceDate", headerText: 'Invoice Date', width: '130px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "InvoiceNo", headerText: 'Invoice No', width: '160px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "AccountTitle", headerText: 'Account Title', width: '150px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "AccountTitleBill", headerText: 'Account Title Bill', width: '150px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "CurrencyName", headerText: 'Currency', width: '135px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "ShopName", headerText: 'Shop Name', width: '140px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "Remarks", headerText: 'Remarks', width: '200px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        // { field: "BrandName", headerText: 'Brand Name', width: '130px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        // { field: "ProductName", headerText: 'Product Name', width: '140px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "TotalInvoiceCost", headerText: 'Total Invoice Cost', width: '130px', textAlign: 'right', headerTextAlign: 'center', other: [{visible:SecurityService.getLoggedInUserId() == "0" ,'format':'n2'}] },
        // { field: "UnitOfMeasurement", headerText: 'Unit of Measurement', width: '160px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        ]

       
       const listActionComplete =  async (grid ,args ,getListService ,selectedRow,setDataSourceState )=>{
        console.log(args )
        console.log(getListService )
        console.log(selectedRow )
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          }
        if (args.requestType == 'save' || args.requestType == 'cancel' ) {
          setDataSourceState([])
          getListService()

        }

        if (args.requestType == 'save' && args.data.MainInvoiceID) {
          console.log(args.data)
          function convertToMozambiqueTime(dateString) {
            // Parse the date string with the GMT offset
            // const gmtDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
            
            // // Convert the parsed date to Mozambique time zone (Africa/Maputo)
            // const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
            
            // return dateInMozambique;

            const gmtDate = moment(dateString, "DD-MMM-YY");

            // Convert the parsed date to Mozambique time zone (Africa/Maputo)
            const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
        
            return dateInMozambique;
        }
          const response = await InvoiceService.editInvoice({
            ...args.data ,
            InvoiceDate:convertToMozambiqueTime(args.data.InvoiceDate),
            ShopID:SecurityService.getLoggedInShopID()});
            console.log(response)
            getListService()

        }
          
       }
       //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************

        // Defines the  boolean
        // @default true
    let  allowTemplate = true
    let  allowGrouping = true
    let  allowExcelExport =true
    let  allowPdfExport = true
    let  showColumnChooser = true
    let  allowPaging = false
    let  allowDataBound = false
    let  allowGridSum = true
       
        // Defines the  string
        // @default Invoice${typeMode} 
        let ListSecurity=props.location.pathname.split('/')[2] == 'Purchase' ? 'PurchaseInvoiceList' : 'SaleInvoiceList'
        let AddSecurity= props.location.pathname.split('/')[2] == 'Purchase' ? 'PurchaseInvoiceAdd':'SaleInvoiceAdd'
        let EditSecurity= props.location.pathname.split('/')[2] == 'Purchase' ? 'PurchaseInvoiceEdit':'SaleInvoiceEdit'
        let DeleteSecurity= props.location.pathname.split('/')[2] == 'Purchase' ? 'PurchaseInvoiceDelete':'SaleInvoiceDelete'
       
       // Defines the  ['Add','Edit','Delete','Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser'] | 'All'
       //  @default 'All'
 let toolbarOptions =['Add','Edit',        
        {text: `Delete`, tooltipText: "Delete", id: "DeleteWithDetail", disabled:!SecurityService.canUserAccess(DeleteSecurity) }     
       ,'Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser',
       props.location.pathname.split('/')[2] == 'Purchase'  ? {
          text: "Preview Voucher", tooltipText: "Preview Voucher", id: "PurchaseInvoiceReport", 
          // disabled: !SecurityService.canUserAccess(ReportSecurity)
          disabled: !SecurityService.getLoggedInUserId() == '0'
         ,mustSelectRow:true
          
       } : {}];
      // Defines the toolbarClicking  function
      
      const dialogRef = useRef(null);
      const showDialog = (header,title,actions =['OK']) => {
        return new Promise((resolve) => {
        // Destroy existing dialog if any
        if (dialogRef.current) {
          dialogRef.current.destroy();
        }
        const okButtons = actions.map((action) => ({
          buttonModel: {
            content: action,
            cssClass: 'e-flat',
            isPrimary: action === 'OK',
          },
          click: (e) => {
            hideDialog();
            resolve(action);
          },
        }));
        // Create a new dialog instance
        dialogRef.current = new DialogInput({
          content: ()=>(<>{title}</>),
          header:header,
          width:'340px',
          showButtons: true,
          buttons:okButtons
        });
        // Show the dialog programmatically
        dialogRef.current.show();

      });

      };
    const hideDialog = () =>{
      if (dialogRef.current) {
      }
      dialogRef.current.hide();
      dialogRef.current.destroy();
      dialogRef.current = null; // Clean up reference after destruction
    }
      const toolbarClicking =  async (grid , args , selectedRow , getListService,setDataSourceState)=>{
        console.log(selectedRow)
        console.log(args)
        if(args?.item?.properties?.id == 'DeleteWithDetail'){
          if(selectedRow == undefined){
        await showDialog('WARNING' ,<span class="error-tooltip">Please select a row first!</span>)
      }else{
        let dialogResult  = await showDialog('Confirmation' ,<span class="error-success">Are you sure you want to delete?</span> , ['OK','CANCEL'])
        console.log(dialogResult)
      if(dialogResult == 'OK'){
        invoiceService.delete(selectedRow.MainInvoiceID).then(async (res)=>{
        await  showDialog('ALERT' ,<span class="error-success">RECORD(S) DELETED SUCESSFULLY</span>)
          await getListService()
            AuditTable.auditDelete('Vouhcer',selectedRow.MainInvoiceID,{
              InvoiceID:selectedRow.InvoiceID,
              InvoiceType:selectedRow.InvoiceType,
              InvoiceDate:moment(selectedRow.InvoiceDate).format('DD-MMM-YY'),
              InvoiceNo:selectedRow.InvoiceNo,
              AccountTitle:selectedRow.AccountTitle,
              AccountTitleBill:selectedRow.AccountTitleBill,
              CurrencyName:selectedRow.CurrencyName,
              ShopName:selectedRow.ShopName,
              AdjustmentAmountFC:selectedRow.AdjustmentAmountFC,
              FreightAmountFC:selectedRow.FreightAmountFC,
              Remarks:selectedRow.Remarks,
              HasFinancialImpact:selectedRow.HasFinancialImpact,

            })
          }).catch((e)=>{
              showDialog('WARNING' ,<span class="error-success">  RECORD(S)  UN-DELETED SUCESSFULLY</span>)

          })
        }
        }
        }
        if(args.item.properties.id == 'PurchaseInvoiceReport' && grid.selectedRowIndex != -1){
          console.log(args.item.properties.id)

          console.log(grid.getSelectedRecords()[0])
let data = {
      FromDate:'01-JAN-01' ,
      ToDate:moment().format('DD-MMM-YY'),
      MainInvoiceID: grid.getSelectedRecords()[0].MainInvoiceID ,
      InvoiceID:0 ,
      InvoiceType: props.location.pathname.split('/')[2]  == 'Purchase' ? 'RI' : 'II',
      AccountID:0,
      CurrencyID:0,
      ShopID :0,
      BrandID:0,
      ProductID:0,
      InvoiceDetailID :0
    }
    let dataSource = await InvoiceService.getScratechGridDataSource(data)      
        // handleRedirectVoucher(grid.getSelectedRecords()[0])
        console.log(dataSource.data.recordset);
        if(dataSource.data.recordset.length > 0){
            let blob = await reportsService.PurchaseInvoiceReport(dataSource.data.recordset,
              selectedRow.InvoiceID,
              selectedRow.InvoiceDate,
              selectedRow.InvoiceNo.toUpperCase(),
              selectedRow.AccountTitle.toUpperCase(),
              selectedRow.AccountTitleBill.toUpperCase(),
              selectedRow.CurrencyName,
              selectedRow.AdjustmentAmountFC.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0.00,
              selectedRow.FreightAmountFC.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0.00,
              (selectedRow.FreightAmountFC + selectedRow.AdjustmentAmountFC).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0.00,
              selectedRow.Remarks,
              selectedRow.TotalInvoiceCost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0.00,
              'download')
              console.log(blob)
            }
        }
      }
      //  const toolbarClicking =  async (grid , args , selectedRow , getListService,setDataSourceState)=>{
      //   console.log(args)
      //   // let dataSource = []
      //   // setDataSourceState(prevData => dataSource = prevData);
      //   // console.log(dataSource);

        
      //  }
       // Defines the 'Descending' | 'Ascending'
       //  @default 'Descending'
      let DefaultSortedColumnSort ='Descending';

       /* Defines the [{
                    TableName:'Invoice' ,
                    TableID:'InvoiceID',
                   // Optional if Delete is Soft // TableSecurity:'InvoiceDelete',
                }]*/
    //  @default [] 
    //   let listDelete =  [{ TableName:'Invoice' ,TableID:'InvoiceID', Optional // TableSecurity:'InvoiceDelete'}]
    let listDelete = [];
       
    // Defines 'Hard' | 'Soft' 
    // @Default 'Hard'
    let deleteType = 'Hard'


/* Defines the [   {field:'IsDeleted',
OPTIONAL HAI operator:'equal' or more check syncfusion documentation
        value:'No'},...more
        ] | []
      @default [] */
    //   let filterColumns = [{ field: 'IsDeleted', value: 'No' }];
      let filterColumns = [];

      /* Defines the [ColumnNames] | []
      @default [] */
    //   let groupColumns = ['IsDeleted','AccountTitleHawalaDr'];
      let groupColumns = [];

/* Defines the 'Dialog' | 'Normal'
      @default 'Dialog' */
      let gridEditSettingMode = 'Dialog';

/* Defines the '<p></p>' | Can give HTML | AND REACT FUNCTION LIKE HeaderTemplate
      @default '<p></p>' */
      let gridEditSettingHeaderTemplate = '<p></p>';

      /* Defines the  [ColumnNames] | []
      @default [] */
           //   let gridSumColumns = ['HawalaCommissionPercent'];
      let gridSumColumns = [];

    console.log({id:SecurityService.getLoggedInUserId() == '0' ? 0 : SecurityService.getLoggedInShopID() , 
      type: inputType == 'Purchase' ? 'RI' : 'II'})
    return (
      
        <IrtizaDynamicInputList
        Id='Invoice'
        ListTitle={`Invoice  ${inputType}  ${props.location.pathname.split('/')[3] } - List`}
        ColumnsList={columns}
        GridDataSource= {[
                    {
                        DataSourceType: 'Function',
                        DataSource:  ()=> InvoiceService.getInvoiceForList(SecurityService.getLoggedInUserId() == 0 ? SecurityService.getLoggedInUserId() : SecurityService.getLoggedInShopID() ,  props.location.pathname.split('/')[2] == 'Purchase' ? 'RI' : 'II', props.location.pathname.split('/')[3]),
                        DataSourceIndexName:props.location.pathname.split('/')[2] == 'Purchase' ? 'InvoicePurchaseSummary':'InvoiceSaleSummary'
                        }
                      ]}
        // DefaultSortedColumnName={'MainInvoiceID'}
        DefaultSortedColumnName={''}
        GridDialog={[
            {
                DialogType:'Self',
                
                // Dialog:<InvoiceAdd Type={inputType} ShopID={SecurityService.getLoggedInUserId() == 0 ? SecurityService.getLoggedInUserId() : SecurityService.getLoggedInShopID()} UserID={SecurityService.getLoggedInUserId() }/>
                Dialog:<InvoiceAdd Type={inputType} ShopID={SecurityService.getLoggedInShopID()} UserID={SecurityService.getLoggedInUserId() }/>
                // Dialog:[
                //   {
                //     Title:'Invoice ',
                //     Inputs:[
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'CurrencyID',value:'CurrencyName'},type:'dropdown',dataSource:{type:'Route',Route:'CurrencyName'},defaultValues:null},
                //       {field:'ExchangeRate'	,type:'float',defaultValues:null},
                //       {field:'HawalaCommissionPercent'	,type:'float',defaultValues:null},
                //       {field:{id:'CurrencyIDHawala',value:'CurrencyNameHawala	'},type:'dropdown',dataSource:{type:'Route',Route:'CurrencyName'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null}
                //     ]
                //   }
                // ]

            }
        ]}
        ListActionComplete={listActionComplete}
        ToolbarClicking ={toolbarClicking}
        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
            // AllowTemplate={allowTemplate}
            ToolbarOption={toolbarOptions}
            // AllowGrouping={allowGrouping}
            // AllowExcelExport={allowExcelExport}
            // AllowPdfExport={allowPdfExport} 
            // ShowColumnChooser={showColumnChooser}
            // AllowPaging={allowPaging}
            // AllowDataBound={allowDataBound}
            ListSecurity={ListSecurity}
            AddSecurity={AddSecurity}
            EditSecurity={EditSecurity}
            DeleteSecurity={DeleteSecurity}
            // DefaultSortedColumnSort={DefaultSortedColumnSort}
            // FilterColumns={filterColumns} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
            // GroupColumns={groupColumns} 
            // ListDelete={listDelete}
            // DeleteType={deleteType} 
            // GridEditSettingMode={gridEditSettingMode}
            // GridEditSettingHeaderTemplate={gridEditSettingHeaderTemplate}
            // GridSumColumns={gridSumColumns}
            AllowGridSum={allowGridSum}
          
            {...props}
            ></IrtizaDynamicInputList>
            
            );
}



export default InvoiceInput;
