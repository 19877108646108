import { propTypes } from 'react-bootstrap/esm/Image';
import http from '../../services/http-common';
import moment from 'moment';
import { PdfGrid,PdfPen, PdfDocument, PdfBorders,PdfPage ,PdfColor,PdfSolidBrush,PdfFontStyle,PdfPageSize,PdfPageOrientation, PdfAlignmentStyle, PdfStringFormat, PdfTextAlignment,PdfStandardFont , PdfFontFamily} from "@syncfusion/ej2-pdf-export";

class ReportService {
    
    
//   getCashBookUsdVoucher(data) {
//         return http.post(`/CashBookUsdVoucher/getSpFinance` , data , {
//             headers: {
//               'Content-Type': 'multipart/form-data',
//             }},
//         ); 
//     }
    getReceivablePayableService(data) {
        return http.post(`/receviablePayable/` , data , {
            // headers: {
            // 'Content-Type': 'multipart/form-data',
            // }
        },
        ); 
    }
    getInventoryWithProfitLossService(ReportType) {
        console.log(ReportType)

            let ReportTypeSplit = ReportType.split(',')
            console.log(ReportTypeSplit)
        let data ={
            ReportType :ReportTypeSplit[0]
           ,MainInvoiceID :ReportTypeSplit[1]
           ,FromDate:ReportTypeSplit[2]
           ,ToDate:ReportTypeSplit[3]
           ,BrandID:ReportTypeSplit[4]
           ,ProductID:ReportTypeSplit[5]
           ,ShopID:ReportTypeSplit[6]
        }
        return http.post(`/inventoryWithProfitLoss/` , data , {
            headers: {
              'Content-Type': 'multipart/form-data',
            }},
        ); 
    }
 

  
    getChartOfAccount() {
        return http.get(`/ReportChartOfAccount` );
    }
    getSaleReport(data) {
        return http.post(`/SaleReport` ,data );
    }
       
    
  getCashBookUsdVoucher(data) {
    return http.post(`/CashBookUsdVoucher/getSpFinance` , data);
}
getCashBookUsdVoucherReport(data) {
  let queryParams = new URLSearchParams(data).toString();
    return http.get(`/ReportsAPI/CashBookUsdVoucherReport?${queryParams}`);
}
// getChartOfAccount() {
//     return http.get(`/ReportChartOfAccount` );
// }
// getSaleReport(data) {
//     return http.post(`/SaleReport` ,data );
// }





async InventoryWithProfitLoss(data,props,type){

console.log(data)
  

  const exportInventoryWithProfitLossToPDF = async (data , fromDate,toDate , type) => {
   
    const document = new PdfDocument();

    document.pageSettings.margins.all = 10
    document.pageSettings.margins.bottom = 5
    document.pageSettings.orientation =  PdfPageOrientation.Landscape
    document.pageSettings.size =  PdfPageSize.a4
    // Set page orientation to landscape
    const page = document.pages.add();
    
      const graphics = page.graphics;
    
      // Add a heading
      const font = new PdfStandardFont(PdfFontFamily.Helvetica, 18);
      const blackBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));
      
      // let fromDate = new Date()
      // let toDate = new Date()
      const datesFont = new PdfStandardFont(PdfFontFamily.Helvetica, 11);
      graphics.drawString('HAP INTERNATIONAL LIMITED - MOZAMBIQUE', font, null, blackBrush, 230, 10);

      graphics.drawString('Inventory Status With Profit / Loss', font, null, blackBrush, 300, 30);
      graphics.drawString(`From Date: ${fromDate}`, datesFont, null, blackBrush, 10, 55);
      graphics.drawString(`To Date: ${toDate}`, datesFont, null, blackBrush, 10, 70);



    
    // Create the grid
    const pdfGrid = new PdfGrid();
          pdfGrid.columns.add(19);  
    // Define the hierarchical columns
    














    const columns = [
      { headerText: "Item Name", field: "ProductName", width: 150 },
      {
        headerText: "Opening",
        columns: [
          { headerText: "Qty.", field: "OpQuantity", width: 70 },
          { headerText: "Rate", field: "OpRate", width: 70 },
          { headerText: "Amt.", field: "OpAmount", width: 70 },
        ],
      },
      {
        headerText: "Purchase",
        columns: [
          { headerText: "Qty.", field: "PurQuantity", width: 70 },
          { headerText: "Rate", field: "PurRate", width: 70 },
          { headerText: "Amt.", field: "PurAmount", width: 70 },
        ],
      },
      {
        headerText: "Trans",
        columns: [
          { headerText: "Qty.", field: "TransQuantity", width: 70 },
        ],
      },
      {
        headerText: "Available",
        columns: [
          { headerText: "Qty.", field: "AvailableQuantity", width: 70 },
          { headerText: "Rate", field: "AvailableRate", width: 70 },
          { headerText: "Amt.", field: "AvailableAmount", width: 70 },
        ],
      },
      {
        headerText: "Closing Stock",
        columns: [
          { headerText: "Qty.", field: "ClosingQuantity", width: 70 },
          { headerText: "Rate", field: "ClosingRate", width: 70 },
          { headerText: "Amt.", field: "ClosingAmount", width: 70 },
        ],
      },
      {
        headerText: "Sale",
        columns: [
          { headerText: "Qty.", field: "SaleQuantity", width: 70 },
          { headerText: "Rate", field: "SaleAvgRate", width: 70 },
          { headerText: "Amt.", field: "SaleAmount", width: 70 },
        ],
      },
      {
        headerText: "Profit / Loss",
        columns: [
          { headerText: "Rate", field: "SaleRateDiff", width: 70 },
          { headerText: "Amt.", field: "profitLossAmt", width: 70 },
        ],
      },
    ];
    







    // Add headers dynamically
    // Adding column headers
     // Adding column headers
  pdfGrid.headers.add(1); // First-level headers
  pdfGrid.headers.add(1); // Second-level headers
  const headerFont = new PdfStandardFont(PdfFontFamily.Helvetica, 11);
  const headerFormat = new PdfStringFormat();
  headerFormat.alignment = PdfTextAlignment.Center;

  let cellIndex = 0;
  // Loop through the columns to add headers
  columns.forEach((col) => {
    if (col.columns) {
      // Add main header with column span
      const headerCell = pdfGrid.headers.getHeader(0).cells.getCell(cellIndex);
      headerCell.value = col.headerText;
      headerCell.columnSpan = col.columns.length;
      headerCell.style.font = headerFont
      headerCell.stringFormat = headerFormat;



      // Add subheaders
      col.columns.forEach((subCol) => {
        const subHeaderCell = pdfGrid.headers.getHeader(1).cells.getCell(cellIndex);
        subHeaderCell.value = subCol.headerText;
        subHeaderCell.style.font = headerFont
        subHeaderCell.stringFormat = headerFormat;
        cellIndex++;
      });
    } else {
      // Add single column header
      
      const headerCell = pdfGrid.headers.getHeader(0).cells.getCell(cellIndex);
      headerCell.value = col.headerText;
      headerCell.style.font = headerFont

      
      headerCell.stringFormat = headerFormat;
      const subHeaderCell = pdfGrid.headers.getHeader(1).cells.getCell(cellIndex);
      subHeaderCell.value = ""; // Leave the subheader row empty
      cellIndex++;
    }
  });

  pdfGrid.columns.getColumn(0).width = 100; // Increase width for ProductName


  // Enable repeating headers
  pdfGrid.repeatHeader = true;

  





  const numericFormat = new PdfStringFormat();
       numericFormat.alignment = PdfTextAlignment.Right;
       
    // Add data rows dynamically
   // Step 1: Group data by ShopName and then by BrandName
const groupedData = data.reduce((acc, row) => {
  const shop = row.ShopName || "Unknown ShopName";
  const brand = row.BrandName || "Unknown BrandName";

  if (!acc[shop]) {
    acc[shop] = {};
  }

  if (!acc[shop][brand]) {
    acc[shop][brand] = [];
  }

  acc[shop][brand].push(row);
  return acc;
}, {});

let grandTotal ={
OpQuantity:0,
OpRate:0,
OpAmount:0,
PurQuantity:0,
PurRate:0,
PurAmount:0,
TransQuantity:0,
AvailableQuantity:0,
AvailableRate:0,
AvailableAmount:0,
ClosingQuantity:0,
ClosingRate:0,
ClosingAmount:0,
SaleQuantity:0,
SaleAvgRate:0,
SaleAmount:0,
SaleRateDiff:0,
ProfitLossAmount:0
}

// Helper function to calculate totals for a given set of rows
const calculateTotals = (rows) => {
  return rows.reduce((totals, row) => {
    totals.OpQuantity += row.OpQuantity;
    totals.OpRate += row.OpRate;
    totals.OpAmount += row.OpAmount;
    totals.PurQuantity += row.PurQuantity;
    totals.PurRate += row.PurRate;
    totals.PurAmount += row.PurAmount;
    totals.TransQuantity += row.TransQuantity;
    totals.AvailableQuantity += row.AvailableQuantity;
    totals.AvailableRate += row.AvailableRate;
    totals.AvailableAmount += row.AvailableAmount;
    totals.ClosingQuantity += row.ClosingQuantity;
    totals.ClosingRate += row.ClosingRate;
    totals.ClosingAmount += row.ClosingAmount;
    totals.SaleQuantity += row.SaleQuantity;
    totals.SaleAvgRate += row.SaleAvgRate;
    totals.SaleAmount += row.SaleAmount;
    totals.SaleRateDiff += row.SaleRateDiff;
    totals.ProfitLossAmount += row.ProfitLossAmount;
    return totals;
  }, {OpQuantity:0,
    OpRate:0,
    OpAmount:0,
    PurQuantity:0,
    PurRate:0,
    PurAmount:0,
    TransQuantity:0,
    AvailableQuantity:0,
    AvailableRate:0,
    AvailableAmount:0,
    ClosingQuantity:0,
    ClosingRate:0,
    ClosingAmount:0,
    SaleQuantity:0,
    SaleAvgRate:0,
    SaleAmount:0,
    SaleRateDiff:0,
    ProfitLossAmount:0}); // Start with a copy of the grandTotal template
};

// Step 2: Render grouped data to PDF
console.log(groupedData)
Object.keys(groupedData).forEach((shop) => {
  const shopRow = pdfGrid.rows.addRow();
  shopRow.cells.getCell(0).value = `SHOP: ${shop}`;
  shopRow.cells.getCell(0).colSpan = 19;
  shopRow.cells.getCell(0).stringFormat = headerFormat;
  shopRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 13));
  shopRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(211, 211, 211))); // Light gray background


  let shopTotal = {
    OpQuantity:0,
OpRate:0,
OpAmount:0,
PurQuantity:0,
PurRate:0,
PurAmount:0,
TransQuantity:0,
AvailableQuantity:0,
AvailableRate:0,
AvailableAmount:0,
ClosingQuantity:0,
ClosingRate:0,
ClosingAmount:0,
SaleQuantity:0,
SaleAvgRate:0,
SaleAmount:0,
SaleRateDiff:0,
ProfitLossAmount:0
  };

  Object.keys(groupedData[shop]).forEach((brand) => {
    const brandRow = pdfGrid.rows.addRow();
    brandRow.cells.getCell(0).value = `  BRAND : ${brand}`;
    brandRow.cells.getCell(0).colSpan = 19;
    brandRow.cells.getCell(0).stringFormat = headerFormat;
    brandRow.style.setFont(headerFont);
    brandRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(230, 230, 250))); // Light purple background
    
    
    const brandRows = groupedData[shop][brand];
    const brandTotal = calculateTotals(brandRows);
    
    brandRows.forEach((row) => {
      const pdfRow = pdfGrid.rows.addRow();
      let cellIndex = 0;

      columns.forEach((col) => {
        if (col.columns) {
          col.columns.forEach((subCol) => {
            pdfRow.cells.getCell(cellIndex).value = row[subCol.field]?.toLocaleString('en-US', { minimumFractionDigits: subCol.field.includes('Rate') ? 2 :0, maximumFractionDigits:  subCol.field.includes('Rate') ? 2 : 0 }) ||  0;
            pdfRow.cells.getCell(cellIndex).stringFormat = numericFormat;
            cellIndex++;
          });
        } else {
          pdfRow.cells.getCell(cellIndex).value = row[col.field]?.toString() || "";
          cellIndex++;
        }
      });
    });

    const brandTotalRow = pdfGrid.rows.addRow();
    brandTotalRow.cells.getCell(0).value = 'BRAND TOTAL';
    brandTotalRow.cells.getCell(0).columnSpan = 1;
    Object.keys(brandTotal).forEach((key, index) => {
      if(!key.includes('Rate')){
      brandTotalRow.cells.getCell(index + 1).value = brandTotal[key].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      brandTotalRow.cells.getCell(index + 1).stringFormat = numericFormat;
      }
    });
    
    brandTotalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 9, PdfFontStyle.Bold));
    
    // Update shop totals
    Object.keys(shopTotal).forEach((key) => {
      shopTotal[key] += brandTotal[key];
    });
  });

  const shopTotalRow = pdfGrid.rows.addRow();
  shopTotalRow.cells.getCell(0).value = 'SHOP TOTAL';
  shopTotalRow.cells.getCell(0).columnSpan = 1;
  Object.keys(shopTotal).forEach((key, index) => {
    if(!key.includes('Rate')){
    shopTotalRow.cells.getCell(index + 1).value = shopTotal[key].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    shopTotalRow.cells.getCell(index + 1).stringFormat = numericFormat;
    }
  });
  shopTotalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 9, PdfFontStyle.Bold));
  shopTotalRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(211, 211, 211)));

  // Update grand totals
  Object.keys(grandTotal).forEach((key) => {
    grandTotal[key] += shopTotal[key];
  });
});

// Finally, render the grand total row
const grandTotalRow = pdfGrid.rows.addRow();
grandTotalRow.cells.getCell(0).value = 'REPORT TOTAL';
grandTotalRow.cells.getCell(0).columnSpan = 1;
Object.keys(grandTotal).forEach((key, index) => {
  console.log(key)
  if(!key.includes('Rate')){
  grandTotalRow.cells.getCell(index + 1).value = grandTotal[key].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  grandTotalRow.cells.getCell(index + 1).stringFormat = numericFormat;
  }
});
grandTotalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 9, PdfFontStyle.Bold));
grandTotalRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(173, 216, 230)));

  
    // Draw the grid
    pdfGrid.draw(page, { x: 0, y:110 });
  
    // Save the PDF document
    // document.save("InventoryWithProfitLoss.pdf");
    // document.destroy();
    if(type == 'save'){
      const pdfBlob = await document.save();
    
      // Destroy the document to release resources
      document.destroy();
    
      return pdfBlob;
     }else{ 
    
       // Save the PDF document to a file
       document.save("InventoryWithProfitLoss.pdf");
       
       
       
       // Destroy the document to release resources
       document.destroy();
     }
     
  };
  
  let blob = await exportInventoryWithProfitLossToPDF(data, props.FromDate, props.ToDate,type)
  return blob
  
}





StockShopWiseDataSource(data , header){


const exportStockShopWiseToPDF = (data , headers) => {
   
 // Create a new PDF document
const document = new PdfDocument();

// Set page settings
document.pageSettings.margins.all = 10;
document.pageSettings.margins.bottom = 5;
document.pageSettings.orientation =  PdfPageOrientation.Landscape
document.pageSettings.size =  PdfPageSize.a4

// Add a new page to the document
const page = document.pages.add();

const graphics = page.graphics;

// Add a heading
const headerFont = new PdfStandardFont(PdfFontFamily.Helvetica, 18);
const blackBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));
graphics.drawString('HAP INTERNATIONAL LIMITED - MOZAMBIQUE', headerFont, null, blackBrush, 190, 10);

graphics.drawString('Shop Wise Stock', headerFont, null, blackBrush, 330, 35);

  const pdfGrid = new PdfGrid();
  pdfGrid.columns.add(13); // Adding 9 columns for the provided data


pdfGrid.headers.add(1);
  const header = pdfGrid.headers.getHeader(0);
  
  
  headers.forEach((headerText, index) => {
    header.cells.getCell(index).value = headerText;
    header.cells.getCell(index).style.font = new PdfStandardFont(PdfFontFamily.Helvetica, 10)
  });

  const headerFormat = new PdfStringFormat();
  headerFormat.alignment = PdfTextAlignment.Center;
  headers.forEach((_, index) => {
    header.cells.getCell(index).stringFormat = headerFormat;
  });


pdfGrid.columns.getColumn(0).width = 100; // Increase width for ProductName

// Enable repeating headers
  pdfGrid.repeatHeader = true;


  const rightAlign = new PdfStringFormat();
  rightAlign.alignment = PdfTextAlignment.Right;



      // Add data rows dynamically
   // Step 1: Group data by ShopName and then by BrandName
const groupedData = data.reduce((acc, row) => {
  // const shop = row.ShopName || "Unknown ShopName";
  const brand = row.BrandName || "Unknown BrandName";

  // if (!acc[shop]) {
  //   acc[shop] = {};
  // }

  if (!acc[brand]) {
    acc[brand] = [];
  }

  acc[brand].push(row);
  return acc;
}, {});

let grandTotal ={
  Shop1BalQty: 0,
  Shop2BalQty: 0,
  Shop3BalQty: 0,
  Shop4BalQty: 0,
  Shop5BalQty: 0,
  Shop6BalQty: 0,
  Shop7BalQty: 0,
  Shop8BalQty: 0,
  Shop9BalQty: 0,
  Shop10BalQty:0,
  Shop15BalQty:0,
  TotalStockQty:0
}


// Step 2: Render grouped data to PD

  Object.keys(groupedData).forEach((brand) => {
    const brandRow = pdfGrid.rows.addRow();
    brandRow.cells.getCell(0).value = `  BRAND : ${brand}`;
    brandRow.cells.getCell(0).colSpan = 13;
    brandRow.cells.getCell(0).stringFormat = headerFormat;
    brandRow.style.setFont( new PdfStandardFont(PdfFontFamily.Helvetica, 11));
    brandRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(230, 230, 250))); // Light purple background
    
    
   
    const brandTotal = {
      Shop1BalQty: 0,
      Shop2BalQty: 0,
      Shop3BalQty: 0,
      Shop4BalQty: 0,
      Shop5BalQty: 0,
      Shop6BalQty: 0,
      Shop7BalQty: 0,
      Shop8BalQty: 0,
      Shop9BalQty: 0,
      Shop10BalQty:0,
      Shop15BalQty:0,
      TotalStockQty:0
    }
    console.log(groupedData[brand]);
    groupedData[brand].forEach((item) => {
      console.log(item);
      const pdfRow = pdfGrid.rows.addRow();
      pdfRow.cells.getCell(0).value = item.ProductName;
      pdfRow.cells.getCell(1).value = item.Shop1BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(2).value = item.Shop2BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(3).value = item.Shop3BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(4).value = item.Shop4BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(5).value = item.Shop5BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(6).value = item.Shop6BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(7).value = item.Shop7BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(8).value = item.Shop8BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(9).value = item.Shop9BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(10).value = item.Shop10BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(11).value = item.Shop15BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(12).value = item.TotalStockQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

      for (let i = 1; i <= 12; i++) {
        pdfRow.cells.getCell(i).stringFormat = rightAlign;
        }
         // Update totals
         brandTotal.Shop1BalQty += item.Shop1BalQty;
         brandTotal.Shop2BalQty += item.Shop2BalQty;
         brandTotal.Shop3BalQty += item.Shop3BalQty;
         brandTotal.Shop4BalQty += item.Shop4BalQty;
         brandTotal.Shop5BalQty += item.Shop5BalQty;
         brandTotal.Shop6BalQty += item.Shop6BalQty;
         brandTotal.Shop7BalQty += item.Shop7BalQty;
         brandTotal.Shop8BalQty += item.Shop8BalQty;
         brandTotal.Shop9BalQty += item.Shop9BalQty;
         brandTotal.Shop10BalQty += item.Shop10BalQty;
         brandTotal.Shop15BalQty += item.Shop15BalQty;
         brandTotal.TotalStockQty += item.TotalStockQty;
    });

    const brandTotalRow = pdfGrid.rows.addRow();
    brandTotalRow.cells.getCell(0).value = 'BRAND TOTAL';
    brandTotalRow.cells.getCell(0).columnSpan = 1;
    Object.keys(brandTotal).forEach((key, index) => {
      console.log(brandTotal)
      if(!key.includes('Rate')){
      brandTotalRow.cells.getCell(index + 1).value = brandTotal[key].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      brandTotalRow.cells.getCell(index + 1).stringFormat = rightAlign;
      }
    });
    
    brandTotalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 9, PdfFontStyle.Bold));
    Object.keys(grandTotal).forEach((key) => {
      grandTotal[key] += brandTotal[key];
    });    
  });


// Finally, render the grand total row
const grandTotalRow = pdfGrid.rows.addRow();
grandTotalRow.cells.getCell(0).value = 'REPORT TOTAL';
grandTotalRow.cells.getCell(0).columnSpan = 1;
Object.keys(grandTotal).forEach((key, index) => {
  console.log(key)
  if(!key.includes('Rate')){
  grandTotalRow.cells.getCell(index + 1).value = grandTotal[key].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  grandTotalRow.cells.getCell(index + 1).stringFormat = rightAlign;
  }
});
grandTotalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 9, PdfFontStyle.Bold));
grandTotalRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(173, 216, 230)));

  
    // Draw the grid
    pdfGrid.draw(page, { x: 0, y:70 });


// Save the PDF document to a file
document.save("StockShopWise.pdf");

// Destroy the document to release resources
document.destroy();
    }
  // exportStockShopWiseToPDF(data, props.FromDate, props.ToDate)
  exportStockShopWiseToPDF(data, header)
  
}

 async LedgerReport  (data,accountTitle,fromDate,toDate,type) {

const  exportLedgerToPDF = async (data ,accountTitle,fromDate,toDate ,type) => {
    // Create a new PDF document
const document = new PdfDocument();

// Set page settings
document.pageSettings.margins.all = 10;
document.pageSettings.margins.bottom = 5;
// document.pageSettings.orientation =  PdfPageOrientation.Landscape
document.pageSettings.size =  PdfPageSize.a4

// Add a new page to the document
const page = document.pages.add();

const graphics = page.graphics;

// Add a heading
const headerFont = new PdfStandardFont(PdfFontFamily.Helvetica, 18);
const subHeaderFont = new PdfStandardFont(PdfFontFamily.Helvetica, 14);
const blackBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));

graphics.drawString('HAP INTERNATIONAL LIMITED - MOZAMBIQUE', headerFont, null, blackBrush, 90, 10);
graphics.drawString(`Accounts Ledger Of ${accountTitle}`, subHeaderFont, null, blackBrush, 150, 35);
graphics.drawString(`From Date ${fromDate} to ${toDate}`, subHeaderFont, null, blackBrush, 180, 55);

  const pdfGrid = new PdfGrid();
  pdfGrid.columns.add(7); // Adding 9 columns for the provided data


pdfGrid.headers.add(1);
  const header = pdfGrid.headers.getHeader(0);
  
  let headers = [
    `Date`, `Voucher`, `Description`, `Cheque`, `Debit`, `Credit`, `Bal`
  ]
  headers.forEach((headerText, index) => {
    header.cells.getCell(index).value = headerText;
    header.cells.getCell(index).style.font = new PdfStandardFont(PdfFontFamily.Helvetica, 10)
  });

  const headerFormat = new PdfStringFormat();
  headerFormat.alignment = PdfTextAlignment.Left;
  headerFormat.verticalAlignment = PdfTextAlignment.Center;
  headers.forEach((_, index) => {
    header.cells.getCell(index).stringFormat = headerFormat;
  });


pdfGrid.columns.getColumn(0).width = 50; // Increase width for ProductName
pdfGrid.columns.getColumn(2).width = 160; // Increase width for ProductName

// Enable repeating headers
  pdfGrid.repeatHeader = true;


  const rightAlign = new PdfStringFormat();
  rightAlign.alignment = PdfTextAlignment.Right;
  rightAlign.verticalAlignment = PdfTextAlignment.Center;
  
  const verticalAlign = new PdfStringFormat();
  verticalAlign.verticalAlignment = PdfTextAlignment.Center  


  data.updatedEntries.forEach((item , index) => {
    console.log(item);
    const pdfRow = pdfGrid.rows.addRow();
    pdfRow.cells.getCell(0).value = item.EntryNo == 0 ? 'Opening' : moment(item.EntryDate).format('DD-MMM-YY') ;
    pdfRow.cells.getCell(1).value = `${item?.FType} - ${item.EntryNo.toString()} `;
    pdfRow.cells.getCell(2).value = item?.AccountTitleCounter;
    pdfRow.cells.getCell(3).value =`${item?.VoucherCategory || ''}\n ${item?.Rem || ''} ` ;
    pdfRow.cells.getCell(4).value = item.MainID == '0' && index == 0 ? null : item.Dr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    pdfRow.cells.getCell(5).value = item.MainID == '0' && index == 0 ? null : item.Cr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    // pdfRow.cells.getCell(4).value = item.Dr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    // pdfRow.cells.getCell(5).value = item.Cr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    pdfRow.cells.getCell(6).value = item.Bal.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    

    for (let i = 0; i <= 6; i++) {
      pdfRow.cells.getCell(i).stringFormat = verticalAlign
      if(i > 3){

        pdfRow.cells.getCell(i).stringFormat = rightAlign;
      }
      }
       // Update totals
    
  });
  const grandTotalRow = pdfGrid.rows.addRow();
  grandTotalRow.cells.getCell(0).value = 'GRAND TOTAL:';
  grandTotalRow.cells.getCell(0).columnSpan = 4;
  
  grandTotalRow.cells.getCell(4).value = data.totalDr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  grandTotalRow.cells.getCell(4).stringFormat = rightAlign;
  
  grandTotalRow.cells.getCell(5).value = data.totalCr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  grandTotalRow.cells.getCell(5).stringFormat = rightAlign;
  

  grandTotalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 9, PdfFontStyle.Bold));
  grandTotalRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(173, 216, 230)));
  
  

 // Draw the grid
 pdfGrid.draw(page, { x: 0, y:80 });

 if(type == 'save'){
  const pdfBlob = await document.save();

  // Destroy the document to release resources
  document.destroy();

  return pdfBlob;
 }else{ 

   // Save the PDF document to a file
   document.save("Ledger.pdf");
   
   
   
   // Destroy the document to release resources
   document.destroy();
 }
    
  }
  let blob = await exportLedgerToPDF(data,accountTitle,fromDate,toDate ,type)
  return blob
}



async StockReportWithValue ( reportData, fromDate, toDate, type ){
  
  const exportStockWithValueToPDF = async (
    reportData,
    fromDate,
    toDate,
    type
    
  ) => {
    // Create a new PDF document
    const pdfDoc = new PdfDocument();
  
    pdfDoc.pageSettings.margins.all = 10
    pdfDoc.pageSettings.margins.bottom = 5
  
    // Add a new page to the PDF
    // const page = pdfDoc.pages.add();
    const page = pdfDoc.pages.add();
    
  
    const graphics = page.graphics;
  
    // Add a heading
    const font = new PdfStandardFont(PdfFontFamily.Helvetica, 18);
    const blackBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));
    
    // let fromDate = new Date()
    // let toDate = new Date()
    const datesFont = new PdfStandardFont(PdfFontFamily.Helvetica, 11);
    graphics.drawString('HAP INTERNATIONAL LIMITED - MOZAMBIQUE', font, null, blackBrush, 65, 10);
    graphics.drawString('Stock Report', font, null, blackBrush, 220, 30);
    graphics.drawString(`From Date: ${fromDate}`, datesFont, null, blackBrush, 10, 50);
    graphics.drawString(`To Date: ${toDate}`, datesFont, null, blackBrush, 10, 65);
  
    // Create a PdfGrid to hold the data
    const pdfGrid = new PdfGrid();
    pdfGrid.columns.add(9); // Adding 9 columns for the provided data
  
    const headerFont = new PdfStandardFont(PdfFontFamily.Helvetica, 11);
    
    // Add header row
    pdfGrid.headers.add(1);
    const header = pdfGrid.headers.getHeader(0);
    const headers = [
      'Item Name',
      'Brand',
      'Op Qty',
      'Pur.',
      'Sale Qty',
      'Transfer Qty',
      'Bal Qty',
      'Avg Rate',
      'Amount '
    ];
    
    headers.forEach((headerText, index) => {
      header.cells.getCell(index).value = headerText;
      header.cells.getCell(index).style.font = headerFont
    });
  
    const headerFormat = new PdfStringFormat();
    headerFormat.alignment = PdfTextAlignment.Center;
    headers.forEach((_, index) => {
      header.cells.getCell(index).stringFormat = headerFormat;
    });
  
    // Set column widths
  pdfGrid.columns.getColumn(0).width = 100; // Increase width for ProductName
  // pdfGrid.columns.getColumn(1).width = 100; // Example for ShopName
  // pdfGrid.columns.getColumn(2).width = 100; // Example for BrandName
  // pdfGrid.columns.getColumn(3).width = 80;  // Example for numeric columns
  // pdfGrid.columns.getColumn(4).width = 80;  // Repeat for other columns as needed
  
    // Enable repeating headers
    pdfGrid.repeatHeader = true;
  
  
    const rightAlign = new PdfStringFormat();
    rightAlign.alignment = PdfTextAlignment.Right;
  
   
    // Group data by `ShopName`, then by `BrandName`
    const groupedData = reportData.reduce((acc, item) => {
      acc[item.ShopName] = acc[item.ShopName] || {};
      acc[item.ShopName][item.BrandName] = acc[item.ShopName][item.BrandName] || [];
      acc[item.ShopName][item.BrandName].push(item);
      return acc;
    }, {});
  
    // Initialize grand totals
    const grandTotals = {
      OpQty: 0,
      RecQty: 0,
      IssQty: 0,
      TransferQty: 0,
      BalQty: 0,
      ValRate: 0,
  
      ValAmount: 0,
    };
  
    // Populate grid with grouped data
    for (const shopName in groupedData) {
      // Add a row for the Shop group title
      const shopRow = pdfGrid.rows.addRow();
      shopRow.cells.getCell(0).value = `Shop: ${shopName}`;
      shopRow.cells.getCell(0).columnSpan = 9;
      shopRow.style.setFont(font);
      shopRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(211, 211, 211))); // Light gray background
  
      const shopTotals = {
        OpQty: 0,
        RecQty: 0,
        IssQty: 0,
        TransferQty: 0,
        BalQty: 0,
        ValRate: 0,
        ValAmount: 0,
      };
  
      for (const brandName in groupedData[shopName]) {
        // Add a row for the Brand group title
        const brandRow = pdfGrid.rows.addRow();
        brandRow.cells.getCell(0).value = `Brand: ${brandName}`;
        brandRow.cells.getCell(0).columnSpan = 9;
        brandRow.style.setFont(headerFont);
        brandRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(230, 230, 250))); // Light purple background
  
        const brandTotals = {
          OpQty: 0,
          RecQty: 0,
          IssQty: 0,
          TransferQty: 0,
          BalQty: 0,
          ValRate: 0,
  
          ValAmount: 0,
        };
  
        // Add rows for each item in the group
        groupedData[shopName][brandName].forEach((item) => {
          const row = pdfGrid.rows.addRow();
          row.cells.getCell(0).value = item.ProductName;
          row.cells.getCell(1).value = item.BrandName;
          row.cells.getCell(2).value = item.OpQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
          row.cells.getCell(3).value = item.RecQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
          row.cells.getCell(4).value = item.IssQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
          row.cells.getCell(5).value = item.TransferQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
          row.cells.getCell(6).value = item.BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
          row.cells.getCell(7).value = item.ValRate.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
          row.cells.getCell(8).value = item.ValAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
          // Align numeric columns to the right
          for (let i = 2; i <= 8; i++) {
            row.cells.getCell(i).stringFormat = rightAlign;
          }
  
          // Update totals
          brandTotals.OpQty += item.OpQty;
          brandTotals.RecQty += item.RecQty;
          brandTotals.IssQty += item.IssQty;
          brandTotals.TransferQty += item.TransferQty;
          brandTotals.BalQty += item.BalQty;
          brandTotals.ValAmount += item.ValAmount;
        });
  
        // Add a subtotal row for the Brand
        const brandTotalRow = pdfGrid.rows.addRow();
        brandTotalRow.cells.getCell(0).value = 'Brand Total';
        brandTotalRow.cells.getCell(0).columnSpan = 2;
        brandTotalRow.cells.getCell(2).value = brandTotals.OpQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        brandTotalRow.cells.getCell(3).value = brandTotals.RecQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        brandTotalRow.cells.getCell(4).value = brandTotals.IssQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        brandTotalRow.cells.getCell(5).value = brandTotals.TransferQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        brandTotalRow.cells.getCell(6).value = brandTotals.BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        brandTotalRow.cells.getCell(7).value = brandTotals.ValRate.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        brandTotalRow.cells.getCell(8).value = brandTotals.ValAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  
        // Align numeric columns to the right
        for (let i = 2; i <= 8; i++) {
          brandTotalRow.cells.getCell(i).stringFormat = rightAlign;
        }
  
        brandTotalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 10, PdfFontStyle.Bold));
  
        // Update shop totals
        for (const key in shopTotals) {
          shopTotals[key] += brandTotals[key];
        }
      }
  
      // Add a subtotal row for the Shop
      const shopTotalRow = pdfGrid.rows.addRow();
      shopTotalRow.cells.getCell(0).value = 'Shop Total';
      shopTotalRow.cells.getCell(0).columnSpan = 2;
      shopTotalRow.cells.getCell(2).value = shopTotals.OpQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      shopTotalRow.cells.getCell(3).value = shopTotals.RecQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      shopTotalRow.cells.getCell(4).value = shopTotals.IssQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      shopTotalRow.cells.getCell(5).value = shopTotals.TransferQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      shopTotalRow.cells.getCell(6).value = shopTotals.BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      shopTotalRow.cells.getCell(7).value = shopTotals.ValRate.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      shopTotalRow.cells.getCell(8).value = shopTotals.ValAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  
      // Align numeric columns to the right
      for (let i = 2; i <= 8; i++) {
        shopTotalRow.cells.getCell(i).stringFormat = rightAlign;
      }
  
      shopTotalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 10, PdfFontStyle.Bold));
      shopTotalRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(211, 211, 211))); // Light gray background
  
      // Update grand totals
      for (const key in grandTotals) {
        grandTotals[key] += shopTotals[key];
      }
    }
  
    // Add a grand total row
    const totalRow = pdfGrid.rows.addRow();
    totalRow.cells.getCell(0).value = 'Grand Total';
    totalRow.cells.getCell(0).columnSpan = 2;
    totalRow.cells.getCell(2).value = grandTotals.OpQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    totalRow.cells.getCell(3).value = grandTotals.RecQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    totalRow.cells.getCell(4).value = grandTotals.IssQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    totalRow.cells.getCell(5).value = grandTotals.TransferQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    totalRow.cells.getCell(6).value = grandTotals.BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    totalRow.cells.getCell(7).value = grandTotals.ValRate.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    totalRow.cells.getCell(8).value = grandTotals.ValAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  
    // Align numeric columns to the right
    for (let i = 2; i <= 8; i++) {
      totalRow.cells.getCell(i).stringFormat = rightAlign;
    }
  
    totalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 10, PdfFontStyle.Bold));
    totalRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(173, 216, 230))); // Light blue background
  
    // Draw the grid on the PDF page
    pdfGrid.draw(page, { x: 0, y:90 });
  
    // Save the PDF
    // pdfDoc.save('Stock-Report-With-Value.pdf');
    // pdfDoc.destroy();
    if(type == 'save'){
      const pdfBlob = await pdfDoc.save();
    
      // Destroy the pdfDoc to release resources
      pdfDoc.destroy();
    
      return pdfBlob;
     }else{ 
    
       // Save the PDF pdfDoc to a file
       pdfDoc.save("Stock-Report-With-Value.pdf");
       
       
       
       // Destroy the pdfDoc to release resources
       pdfDoc.destroy();
     }
     
  };
  let blob = await exportStockWithValueToPDF(reportData,fromDate,toDate,type)
  return blob
  
}



async PurchaseInvoiceReport  (
  data,
  InvoiceID,
  InvoiceDate,
  InvoiceNo,
  AccountTitle,
  AccountTitleBill,
  CurrencyName,
  AdjustmentAmountFC,
  FreightAmountFC,
  TotalCost,
  Remarks,
  TotalInvoiceCost,
  type) {

  const  exportPurchaseInvoiceToPDF = async (data,
    InvoiceID,
    InvoiceDate,
    InvoiceNo,
    AccountTitle,
    AccountTitleBill,
    CurrencyName,
    AdjustmentAmountFC,
    FreightAmountFC,
    TotalCost,
    Remarks,
    TotalInvoiceCost,
    type) => {
      console.log(data)
      // Create a new PDF document
  const document = new PdfDocument();
  
  // Set page settings
  document.pageSettings.margins.all = 10;
  document.pageSettings.margins.bottom = 5;
  // document.pageSettings.orientation =  PdfPageOrientation.Landscape
  document.pageSettings.size =  PdfPageSize.a4
  
  // Add a new page to the document
  const page = document.pages.add();
  
  const graphics = page.graphics;
  
  // Add a heading
  const headerFont = new PdfStandardFont(PdfFontFamily.Helvetica, 18);
  const subHeaderFont = new PdfStandardFont(PdfFontFamily.Helvetica, 14);
  const propsHeaderFont = new PdfStandardFont(PdfFontFamily.Helvetica, 9);
  const blackBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));
  
  graphics.drawString('HAP INTERNATIONAL LIMITED - MOZAMBIQUE', headerFont, null, blackBrush, 90, 10);
  graphics.drawString(`PURCHASE INVOICE`, subHeaderFont, null, blackBrush, 220, 35);

  graphics.drawString(`INVOICE ID:  ${InvoiceID}`, propsHeaderFont, null, blackBrush, 10, 55);
  graphics.drawString(`DATE:  ${moment(InvoiceDate).format('DD-MMM-YY')}`, propsHeaderFont, null, blackBrush, 150, 55);
  graphics.drawString(`INVOICE NO:  ${InvoiceNo}`, propsHeaderFont, null, blackBrush, 270, 55);
  graphics.drawString(`CURRENCY:  ${CurrencyName}`, propsHeaderFont, null, blackBrush, 395, 55);
  
  
  graphics.drawString(`ACCOUNT TITLE: ${AccountTitle}`, propsHeaderFont, null, blackBrush, 10, 75);
  
  graphics.drawString(`ACCOUNT TITLE BILL:  ${AccountTitleBill}`, propsHeaderFont, null, blackBrush, AccountTitle.length < 17 ? 150 : 275   , 75);

  graphics.drawString(`ADJUSTMENT AMT FC:  ${AdjustmentAmountFC}`, propsHeaderFont, null, blackBrush, 10, 95);
  graphics.drawString(`FREIGHT AMT FC:  ${FreightAmountFC}`, propsHeaderFont, null, blackBrush, 175, 95);
  graphics.drawString(`FREIGHT ADJUSTMENT COST:  ${TotalCost}`, propsHeaderFont, null, blackBrush, 350, 95);

  graphics.drawString(`REMARKS:  ${Remarks}`, propsHeaderFont, null, blackBrush, 10, 115);
  
    const pdfGrid = new PdfGrid();
    pdfGrid.columns.add(9); // Adding 9 columns for the provided data
  
  
  pdfGrid.headers.add(1);
    const header = pdfGrid.headers.getHeader(0);
    
    
    let headers = [
      `BRAND NAME`, `PRODUCT NAME`, `INVOICE QTY`, `INVOICE RATE`, `INVOICE AMOUNT`, `U O M`, `WEIGHT PER BALE`, `INVOICE WEIGHT`, `U O M WEIGHT`
    ]
    headers.forEach((headerText, index) => {
      header.cells.getCell(index).value = headerText;
      header.cells.getCell(index).style.font = new PdfStandardFont(PdfFontFamily.Helvetica, 10)
    });
  
    const headerFormat = new PdfStringFormat();
    headerFormat.alignment = PdfTextAlignment.Left;
    headerFormat.verticalAlignment = PdfTextAlignment.Center;
    headers.forEach((_, index) => {
      header.cells.getCell(index).stringFormat = headerFormat;
    });
  
  
  pdfGrid.columns.getColumn(0).width = 90; // Increase width for ProductName
  pdfGrid.columns.getColumn(1).width = 100; // Increase width for ProductName
  
  // Enable repeating headers
    pdfGrid.repeatHeader = true;
  
  
    const rightAlign = new PdfStringFormat();
    rightAlign.alignment = PdfTextAlignment.Right;
    rightAlign.verticalAlignment = PdfTextAlignment.Center;
    
    const verticalAlign = new PdfStringFormat();
    verticalAlign.verticalAlignment = PdfTextAlignment.Center  
  
    const Totals = {
      InvoiceQuantity: 0,
      InvoiceRate: 0,
      InvoiceAmount: 0,
      WeightPerBale: 0,
      InvoiceWeight: 0,
    };
  
    data.forEach((item , index) => {
      console.log(item);
      const pdfRow = pdfGrid.rows.addRow();
      pdfRow.cells.getCell(0).value = item.BrandName ;
      pdfRow.cells.getCell(1).value = `${item?.ProductName}`;
      pdfRow.cells.getCell(2).value = item?.InvoiceQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
      pdfRow.cells.getCell(3).value =`${item?.InvoiceRate.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` ;
      pdfRow.cells.getCell(4).value = item?.InvoiceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });;
      pdfRow.cells.getCell(5).value = `${item?.UnitOfMeasurement}`;
      pdfRow.cells.getCell(6).value = item.WeightPerBale.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      pdfRow.cells.getCell(7).value = item.InvoiceWeight.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      pdfRow.cells.getCell(8).value = item.UnitOfMeasurementWeight;
      
      
      Totals.InvoiceQuantity += item.InvoiceQuantity;
      Totals.InvoiceRate += item.InvoiceRate;
      Totals.InvoiceAmount += item.InvoiceAmount;
      Totals.WeightPerBale += item.WeightPerBale;
      Totals.InvoiceWeight += item.InvoiceWeight;
      
      for (let i = 0; i <= 8; i++) {
        pdfRow.cells.getCell(i).stringFormat = verticalAlign
        if(i > 1 && i < 5){
  
          pdfRow.cells.getCell(i).stringFormat = rightAlign;
        }
        if(i > 5 && i < 8){
  
          pdfRow.cells.getCell(i).stringFormat = rightAlign;
        }
        }
         // Update totals
      
    });
    const grandTotalRow = pdfGrid.rows.addRow();
    grandTotalRow.cells.getCell(0).value = 'REPORT TOTAL';
    grandTotalRow.cells.getCell(1).value = Totals.InvoiceQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    grandTotalRow.cells.getCell(1).columnSpan = 2;
    // grandTotalRow.cells.getCell(3).value = Totals.InvoiceRate.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    grandTotalRow.cells.getCell(3).value = Totals.InvoiceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    grandTotalRow.cells.getCell(3).columnSpan = 2;
    // grandTotalRow.cells.getCell(6).value = Totals.WeightPerBale.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    grandTotalRow.cells.getCell(6).value = Totals.InvoiceWeight.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    grandTotalRow.cells.getCell(6).columnSpan = 2;

    grandTotalRow.cells.getCell(1).stringFormat = rightAlign;
    grandTotalRow.cells.getCell(3).stringFormat = rightAlign;
    // grandTotalRow.cells.getCell(4).stringFormat = rightAlign;
    grandTotalRow.cells.getCell(6).stringFormat = rightAlign;
    // grandTotalRow.cells.getCell(7).stringFormat = rightAlign;
    // grandTotalRow.cells.getCell(4).value = data.totalDr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    // grandTotalRow.cells.getCell(4).stringFormat = rightAlign;
    
    // grandTotalRow.cells.getCell(5).value = data.totalCr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    // grandTotalRow.cells.getCell(5).stringFormat = rightAlign;
    
  
    grandTotalRow.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 9, PdfFontStyle.Bold));
    grandTotalRow.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(173, 216, 230)));
    
    // const InvoiceCost1 = pdfGrid.rows.addRow();
    // InvoiceCost1.cells.getCell(0).columnSpan = 9 
    
    // InvoiceCost1.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(255, 255, 255)));
    const InvoiceCost = pdfGrid.rows.addRow();
    InvoiceCost.cells.getCell(0).columnSpan = 9 
    InvoiceCost.cells.getCell(0).value = `TOTAL COST: ${TotalInvoiceCost}`;

    // const InvoiceCost2 = pdfGrid.rows.addRow();
    // InvoiceCost2.cells.getCell(0).columnSpan = 9 
    // InvoiceCost2.cells.getCell(0).value = ;


    // const TotalInvoiceCost = pdfGrid.rows.addRow();

    InvoiceCost.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 9, PdfFontStyle.Bold));
    //InvoiceCost2.style.setFont(new PdfStandardFont(PdfFontFamily.Helvetica, 9, PdfFontStyle.Bold));
    InvoiceCost.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(173, 216, 230)));
    //InvoiceCost2.style.setBackgroundBrush(new PdfSolidBrush(new PdfColor(173, 216, 230)));
  
   // Draw the grid
   pdfGrid.draw(page, { x: 0, y:130 });
  
   if(type == 'save'){
    const pdfBlob = await document.save();
  
    // Destroy the document to release resources
    document.destroy();
  
    return pdfBlob;
   }else{ 
  
     // Save the PDF document to a file
     document.save("PurchaseInvoice.pdf");
     
     
     
     // Destroy the document to release resources
     document.destroy();
   }
      
    }
    let blob = await exportPurchaseInvoiceToPDF(
      data,
      InvoiceID,
      InvoiceDate,
      InvoiceNo,
      AccountTitle,
      AccountTitleBill,
      CurrencyName,
      AdjustmentAmountFC,
      FreightAmountFC,
      TotalCost,
      Remarks,
      TotalInvoiceCost,
      type)
    return blob
  }
  

}

export default new ReportService();